import { Container } from 'react-bootstrap';
import './CarList.scss';
import CarListItem from './CarListItem';
import CollapsedList from '../common/CollapsedList';
import { useState } from 'react';

function CarList() {
    const testJson = [
        {
            title: "LUXURY",
            count: "1360",
            img: "luxury"
        },
        {
            title: "SPORTS CAR",
            count: "1360",
            img: "sports_car"
        },
        {
            title: "CAR WITH DRIVER",
            count: "1360",
            img: "car_with_driver"
        },
        {
            title: "LOW PRICE",
            count: "1360",
            img: "low_price"
        },
        {
            title: "SUV",
            count: "1360",
            img: "suv"
        },
        {
            title: "MONTHLY",
            count: "1360",
            img: "monthly"
        },
        {
            title: "LUXURY",
            count: "1360",
            img: "luxury2"
        },
        {
            title: "ELECTRIC",
            count: "1360",
            img: "electric"
        },
        {
            title: "SAFE DRIVER",
            count: "1360",
            img: "safe_driver"
        },
        {
            title: "HATCHBACK",
            count: "1360",
            img: "hatchback"
        },
        {
            title: "CONVERTIBLE",
            count: "1360",
            img: "convertible"
        },
        {
            title: "SEDAN",
            count: "1360",
            img: "sedan"
        },
        {
            title: "COUPE",
            count: "1360",
            img: "coupe"
        },
        {
            title: "SPECIAL EDITION",
            count: "1360",
            img: "special_edition"
        },
        {
            title: "PICK UP",
            count: "1360",
            img: "pick_up"
        },
        {
            title: "CROSSOVER",
            count: "1360",
            img: "crossover"
        },
        {
            title: "CLASSIC",
            count: "1360",
            img: "classic"
        },
        {
            title: "ELECTRIC VEHICLE",
            count: "1360",
            img: "electric_vehicle"
        },
    ]

    const sizeCounts = {
        max: 10,
        xxl: 8,
        xl: 8,
        lg: 6,
        md: 8,
        sm: 9
    }

    const [currentIndex, setCurrentIndex] = useState(-1)

    const onSelect = (index) => {
        // setCurrentIndex(index)
        // console.log(currentIndex)
    }

    const itemsList = testJson.map((item, index) => {
        return <CarListItem key={index}
            item={item} index={index} onSelect={onSelect} isSelected={currentIndex === index}
        />
    })

    return (
        <Container className='car-list'>
            <div className='title'>
                Find car rental and driver services near you
            </div>
            <CollapsedList sizeCounts={sizeCounts} itemsList={itemsList} />

        </Container>
    );
}

export default CarList;
