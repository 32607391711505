import { Collapse } from 'react-bootstrap';
import './CollapsedDropdown.scss';
import React, { useState } from 'react';

function CollapsedDropdown(props) {

    const {title} = props

    const [isOpen, setOpen] = useState(false)

    return (
        <div className='collapsed-dropdown'>
            <div className='collapsed-dropdown__toggle'
                onClick={() => setOpen(!isOpen)}
                aria-controls="collapse-list"
                aria-expanded={isOpen}>
                <div className='collapsed-dropdown__toggle-label'>
                    {title}
                </div>
                <div className={`collapsed-dropdown__toggle-arrow ${!isOpen && 'collapsed-dropdown__toggle-arrow-down'}`}></div>
            </div>
            <Collapse in={isOpen}>
                <div className='collapse-container'>
                    {props.children}

                </div>
            </Collapse>
        </div>
    );
}

export default CollapsedDropdown;
