import { Col, Container, Row } from 'react-bootstrap';
import './Footer.scss';
import { useContext } from 'react';
import { LangContext } from '../common/LanguageWrapper';
// import GoogleMapReact from 'google-map-react';

function Footer(props) {

    // const mapProps = {
    //     center: {
    //         lat: 25.18428,
    //         lng: 55.26353
    //     },
    //     zoom: 11
    // }

    const langContext = useContext(LangContext)

    return (
        <div className='footer'>
            <Container className='footer-container'>
                <Row className='cols-block'>
                    <Col xs={12} lg={4} xl={3}>
                        <div className='logo'> </div>
                        <p>
                            Find the best deals for budget and luxury / sports car rentals, chauffeur service and driver on hire service. Headquartered in Dubai, our services are available in select cities across the globe.
                        </p>
                        <div className='download-block'>
                            <div className='buttons'>
                                <button className='download-button icon-app-store'></button>
                                <button className='download-button icon-google-play'></button>
                            </div>
                            <div className='label'>
                                Download the RentMe Mobile App
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={2}>
                        <p><a href='/'>Dubai Car Rental FAQs</a></p>
                        <p><a href='/'>Car Rental Blog</a></p>
                        <p><a href='/'>Rent by Brand</a></p>
                        <p><a href='/'>Yacht Rental</a></p>
                        <p><a href='/'>Desert Safari Dubai</a></p>
                        <p><a href='/'>Car with Driver</a></p>
                        <p><a href='/'>Car Rental Directory</a></p>
                        <p><a href='/'>Ramadan Car Rental Offers</a></p>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={2}>
                        <p><a href='/'>About Us</a></p>
                        <p><a href='/'>Car Rental App</a></p>
                        <p><a href='/'>Terms & Conditions</a></p>
                        <p><a href='/'>Privacy Policy</a></p>
                        <p><a href='/'>Terms of Use</a></p>
                        <p><a href='/'>Contact Us</a></p>
                        <p><a href='/'>Sitemap XML</a></p>
                        <p><a href='/'>FAQ</a></p>
                    </Col>
                    <Col xs={12} md={6} xl={2}>
                        <p><b>For Inquiries & Support</b></p>
                        <p>+971585128111</p>
                        <p>info@oneclickdrive.com</p>
                        <p><b>For Car with Driver</b></p>
                        <p>+971585782509</p>
                        <p><b>Advertise With Us</b></p>
                        <p>+971567691449</p>
                        <p>sales@oneclickdrive.com</p>
                    </Col>
                    <Col xs={12} md={6} xl={3}>
                        <p>
                            <span style={{ fontWeight: '16px' }}>Address</span><br />
                            1501, Bayswater Tower, Marasi Drive, Business Bay, Dubai, UAE
                        </p>
                        <div className='map map-image'>
                            {/* <GoogleMapReact bootstrapURLKeys={{ key: "" }}
                                defaultCenter={mapProps.center}
                                defaultZoom={mapProps.zoom}
                            >
                            </GoogleMapReact> */}
                        </div>
                    </Col>
                </Row>

                <Row className='lang-panel'>
                    <Col
                        id='en'
                        onClick={langContext.selectLanguage}
                        className={`lang-item ${langContext.locale === 'en' ? 'lang-selected' : 'lang-unselected'}`}>
                        English
                    </Col>
                    <Col
                        id='fr'
                        onClick={langContext.selectLanguage}
                        className={`lang-item ${langContext.locale === 'fr' ? 'lang-selected' : 'lang-unselected'}`}>
                        Français
                    </Col>
                    <Col
                        id='de'
                        onClick={langContext.selectLanguage}
                        className={`lang-item ${langContext.locale === 'de' ? 'lang-selected' : 'lang-unselected'}`}>
                        Deutsch
                    </Col>
                    <Col
                        id='ru'
                        onClick={langContext.selectLanguage}
                        className={`lang-item ${langContext.locale === 'ru' ? 'lang-selected' : 'lang-unselected'}`}>
                        русский
                    </Col>
                    <Col
                        id='tr'
                        onClick={langContext.selectLanguage}
                        className={`lang-item ${langContext.locale === 'tr' ? 'lang-selected' : 'lang-unselected'}`}>
                        Türkçe
                    </Col>
                    <Col
                        id='es'
                        onClick={langContext.selectLanguage}
                        className={`lang-item ${langContext.locale === 'es' ? 'lang-selected' : 'lang-unselected'}`}>
                        Espanol
                    </Col>
                    <Col
                        id='zh'
                        onClick={langContext.selectLanguage}
                        className={`lang-item ${langContext.locale === 'zh' ? 'lang-selected' : 'lang-unselected'}`}>
                        Chinese
                    </Col>
                    <Col
                        id='it'
                        onClick={langContext.selectLanguage}
                        className={`lang-item ${langContext.locale === 'it' ? 'lang-selected' : 'lang-unselected'}`}>
                        Italian
                    </Col>
                    <Col
                        id='de'
                        onClick={langContext.selectLanguage}
                        className={`lang-item ${langContext.locale === 'de' ? 'lang-selected' : 'lang-unselected'}`}>
                        German
                    </Col>
                </Row>
                <div className='call-panel'>
                    <div className='call-button call-phone'></div>
                    <div className='call-button call-telegram'></div>
                    <div className='call-button call-messenger'></div>
                    <div className='call-button call-whatsapp'></div>
                    <div className='call-button call-mail'></div>
                </div>

            </Container>
        </div>
    );
}

export default Footer;
