import { Container, Nav, NavDropdown, Navbar, Offcanvas } from 'react-bootstrap'
import './AppHeader.scss'
import CustomDropdownMobileMenu from '../common/CustomDropdownMobileMenu'
import { useContext, useState } from 'react'
import CustomDropdownMenu from '../common/CustomDropdownMenu'
import { LangContext } from '../common/LanguageWrapper';

function AppHeader() {
    const carsData = {
        href: '#/rentacar',
        items: [
            {
                text: 'Lorem ipsum dolor',
                href: '#/rentacar',
                sub: [
                    {
                        text: 'Lorem ipsum dolor',
                        href: '#/rentacar'
                    },
                    {
                        text: 'Lorem ipsum dolor',
                        href: '#/rentacar'
                    },
                    {
                        text: 'Lorem ipsum dolor',
                        href: '#/rentacar'
                    },
                ]
            },
            {
                text: 'Lorem ipsum dolor',
                href: '#/rentacar'
            },
            {
                text: 'Lorem ipsum dolor',
                href: '#/rentacar'
            },
        ]
    }

    const brandsData = {
        href: '#/carbrands',
        items: [
            {
                text: "BMW",
                img: "bmw",
                href: '#/carbrands'
            },
            {
                text: "Volxwagen",
                img: "vw",
                href: '#/carbrands'
            },
            {
                text: "Audi",
                img: "audi",
                href: '#/carbrands'
            },
            {
                text: "Volvo",
                img: "volvo",
                href: '#/carbrands'
            },
            {
                text: "BMW",
                img: "bmw",
                href: '#/carbrands'
            },
            {
                text: "Volxwagen",
                img: "vw",
                href: '#/carbrands'
            },
            {
                text: "Audi",
                img: "audi",
                href: '#/carbrands'
            },
            {
                text: "Volvo",
                img: "volvo",
                href: '#/carbrands'
            },
            {
                text: "BMW",
                img: "bmw",
                href: '#/carbrands'
            },
            {
                text: "Volxwagen",
                img: "vw",
                href: '#/carbrands'
            },
            {
                text: "Audi",
                img: "audi",
                href: '#/carbrands'
            },
            {
                text: "Volvo",
                img: "volvo",
                href: '#/carbrands'
            },
            {
                text: "BMW",
                img: "bmw",
                href: '#/carbrands'
            },
            {
                text: "Volxwagen",
                img: "vw",
                href: '#/carbrands'
            },
            {
                text: "Audi",
                img: "audi",
                href: '#/carbrands'
            },
            {
                text: "Volvo",
                img: "volvo",
                href: '#/carbrands'
            },
            {
                text: "BMW",
                img: "bmw",
                href: '#/carbrands'
            },
            {
                text: "Volxwagen",
                img: "vw",
                href: '#/carbrands'
            },
            {
                text: "Audi",
                img: "audi",
                href: '#/carbrands'
            },
            {
                text: "Volvo",
                img: "volvo",
                href: '#/carbrands'
            },
            {
                text: "BMW",
                img: "bmw",
                href: '#/carbrands'
            },
            {
                text: "Volxwagen",
                img: "vw",
                href: '#/carbrands'
            },
            {
                text: "Audi",
                img: "audi",
                href: '#/carbrands'
            },
            {
                text: "Volvo",
                img: "volvo",
                href: '#/carbrands'
            },
        ]
    }

    const driverData = {
        href: '#/carwithdriver',
        items: [
            {
                text: 'Lorem ipsum dolor',
                href: '#/carwithdriver',
            },
            {
                text: 'Lorem ipsum dolor',
                href: '#/carwithdriver'
            },
            {
                text: 'Lorem ipsum dolor',
                href: '#/carwithdriver'
            },
        ]
    }

    const testLocation = {
        href: '',
        items: [
            {
                text: "Dubai",
                href: ''
            },
            {
                text: "Dubai1",
                href: ''
            },
            {
                text: "Dubai2",
                href: ''
            },
            {
                text: "Dubai3",
                href: ''
            },
        ]
    }



    const testCurrency = {
        href: '',
        items: [
            {
                text: "AED",
                href: ''
            },
            {
                text: "USD",
                href: ''
            },
            {
                text: "RUB",
                href: ''
            },
        ]
    }

    const testLanguage = {
        href: '',
        items: [
            {
                text: 'English',
                id: 'en',
                href: ''
            },
            {
                text: 'Français',
                id: 'fr',
                href: ''
            },
            {
                text: 'Deutsch',
                id: 'de',
                href: ''
            },
            {
                text: 'Русский',
                id: 'ru',
                href: ''
            },
            {
                text: 'Türkçe',
                id: 'tr',
                href: ''
            },
        ]
    }

    const [currentLocation, setCurrentLocation] = useState(testLocation.items[0])
    const onLocationClick = (e, k) => {
        setCurrentLocation(testLocation.items[k])
    }

    const [currentCurrency, setCurrentCurrency] = useState(testCurrency.items[0])
    const onCurrencyClick = (e, k) => {
        setCurrentCurrency(testCurrency.items[k])
    }

    const langContext = useContext(LangContext)
    const [currentLanguage, setCurrentLanguage] = useState(testLanguage.items[0])
    const onLanguageClick = (e, k) => {
        console.log(k)
        setCurrentLanguage(testLanguage.items[k])
        langContext.selectLanguageById(testLanguage.items[k].id)
    }

    const onLoginClick = (e) => {
    }

    const [menuOpen, setMenuOpen] = useState(false)

    const onMenuClick = () => {
        setMenuOpen(!menuOpen)
    }

    const handleClose = () => setMenuOpen(false)

    return (
        <header className="app-header">
            <h1 className='d-none'> </h1>
            <Container className='header-container'>
                <Navbar
                    expand='md'
                    className="bg-body-tertiary mobile-menu"

                >
                    <Navbar.Toggle
                        aria-controls={`offcanvasNavbar-expand-md`}
                        onClick={onMenuClick}
                    />
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-md`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
                        placement="start"
                        show={menuOpen}
                        onHide={handleClose}
                    >
                        <Offcanvas.Header>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                                <div className='logo offcanvas-logo'></div>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <CustomDropdownMobileMenu
                                text='Rent a car'
                                icon='icon-car'
                                data={carsData}
                                onMenuClick={onMenuClick}
                            >
                            </CustomDropdownMobileMenu>

                            <CustomDropdownMobileMenu
                                text='Car brands'
                                icon='icon-brand'
                                data={brandsData}
                                type={'icon'}
                                onMenuClick={onMenuClick}
                            >
                            </CustomDropdownMobileMenu>

                            <CustomDropdownMobileMenu
                                text='Car with driver'
                                icon='icon-driver'
                                data={driverData}
                                onMenuClick={onMenuClick}
                            >
                            </CustomDropdownMobileMenu>

                            <CustomDropdownMobileMenu
                                text='Dubai'
                                icon='icon-gps'
                            >
                            </CustomDropdownMobileMenu>

                            <CustomDropdownMobileMenu
                                text='AED'
                                icon='icon-aed'
                            >
                            </CustomDropdownMobileMenu>

                            <CustomDropdownMobileMenu
                                text='English'
                                icon='icon-language'
                            >
                            </CustomDropdownMobileMenu>

                            <CustomDropdownMobileMenu
                                text='Profile'
                                icon='icon-login'
                            >
                            </CustomDropdownMobileMenu>

                            <div className='download-block'>
                                <div className='download-block-buttons'>
                                    <button className='download-button icon-app-store'></button>
                                    <button className='download-button icon-google-play'></button>
                                </div>
                                <div className='label'>
                                    Download the RentMe Mobile App
                                </div>
                            </div>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Navbar>
                <div className='d-flex align-items-center'>
                    <a className='logo' href='/'> </a>
                </div>
                <div className='header-menu'>
                    <div>
                        <CustomDropdownMenu
                            text={currentLocation.text}
                            icon={'header-icon icon-gps'}
                            data={testLocation}
                            type={'button'}
                            onButtonClick={onLocationClick}
                            arrow={false}
                        />
                    </div>
                    <div>
                        <CustomDropdownMenu
                            text={currentCurrency.text}
                            icon={'header-icon icon-aed'}
                            data={testCurrency}
                            type={'button'}
                            onButtonClick={onCurrencyClick}
                            arrow={false}
                        />
                    </div>
                    <div>
                    <CustomDropdownMenu
                            text={currentLanguage.text}
                            icon={'header-icon icon-language'}
                            data={testLanguage}
                            type={'button'}
                            onButtonClick={onLanguageClick}
                            arrow={false}
                            withIds={true}
                        />
                    </div>
                    <div>
                        <div className='header-menu-item' onClick={onLoginClick}>
                            <div>
                                <div className='header-icon icon-login'></div>
                            </div>
                            <div className='d-flex align-items-center'>
                                <div className='header-item-text'>
                                    Login/Signup
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Container>
        </header >
    )
}

export default AppHeader
