import { useState } from 'react';
import './CustomDropdownMenu.scss';
import { Image } from 'react-bootstrap';

function CustomDropdownMenu(props) {

    const { type, text, icon, iconActive, isActive, data, onButtonClick, arrow = true, withIds = false } = props

    const [isOpen, setOpen] = useState(false)

    const onHover = () => {
        setOpen(true)
    }

    const onOut = () => {
        setOpen(false)
    }

    const onClick = (e, k) => {
        if (type === 'button') {
            // onButtonClick(e, k)
        }
    }

    const handleClick = (e, k) => {
        if (type === 'button') {
            onButtonClick(e, k)
            setOpen(false)
        }
    }

    return (
        <div className='nav-dd'>
            <button className='nav-dd'
                onMouseEnter={onHover}
                onMouseLeave={onOut}
                onClick={onClick}>

                {data.href === undefined || data.href === null || data.href === '' ? (
                    <div>
                        <span className={`drop-span ${isActive ? 'drop-span-active' : ''}`}>
                            <i className={`icon-dd ${isActive ? iconActive : icon}`}></i>
                            {text}
                            <div className={`${arrow ? 'arrow-icon' : ''} ${isActive ? 'arrow-icon-active' : ''}`} />
                        </span>
                    </div>
                ) : (
                    <a href={data.href}>
                        <span className={`drop-span ${isActive ? 'drop-span-active' : ''}`}>
                            <i className={`icon-dd ${isActive ? iconActive : icon}`}></i>
                            {text}
                            <div className={`${arrow ? 'arrow-icon' : ''} ${isActive ? 'arrow-icon-active' : ''}`} />
                        </span>
                    </a>
                )}
                <nav className={`nav-dd-menu ${isOpen ? 'dd-active' : ''} ${type === 'icon' ? 'nav-dd-grid-menu' : ''}`}>
                    {type === 'icon' ? (
                        <ul className='nav-dd-menu__grid'>
                            {data.items !== undefined && data.items.map((item, index) => {
                                return <li
                                    className='nav-dd-menu__grid-item'
                                    key={index}
                                    onClick={event => handleClick(event, index)}
                                    id={withIds ? item.id : ''}
                                >
                                    {data.href === undefined || data.href === null || data.href === '' ? (
                                        <div>
                                            <Image
                                                className='brand-img'
                                                src={process.env.PUBLIC_URL + `/images/brands/${item.img}.png`}>
                                            </Image>
                                            {item.text}
                                        </div>
                                    ) : (
                                        <a href={item.href}>
                                            <Image
                                                className='brand-img'
                                                src={process.env.PUBLIC_URL + `/images/brands/${item.img}.png`}>
                                            </Image>
                                            {item.text}
                                        </a>
                                    )}
                                </li>
                            })}
                        </ul>
                    ) : (
                        <ul className='nav-dd-menu__list'>
                            {data.items !== undefined && data.items.map((item, index) => {
                                return item['sub'] !== undefined ? (
                                    <CustomDropdownSubMenu
                                        subdata={item.sub}
                                        key={index}>
                                        <a href={item.href}>{item.text}</a>
                                    </CustomDropdownSubMenu>
                                ) : (
                                    <li
                                        className='nav-dd-menu__item'
                                        key={index}
                                        onClick={event => handleClick(event, index)}
                                        id={withIds ? item.id : ''}>
                                        {data.href === undefined || data.href === null || data.href === '' ? (
                                            <div>{item.text}</div>
                                        ) : (
                                            <a href={item.href}>{item.text}</a>
                                        )}

                                    </li>
                                )
                            })}
                        </ul>
                    )}

                </nav>
            </button>
        </div >

    );
}

function CustomDropdownSubMenu(props) {

    const { subdata } = props

    const [isOpen, setOpen] = useState(false)

    const onHover = () => {
        setOpen(true)
    }

    const onOut = () => {
        setOpen(false)
    }

    const onClick = () => {

    }

    return (


        <li className='nav-dd-menu__item'
            onMouseEnter={onHover}
            onMouseLeave={onOut}
            onClick={onClick}>

            {props.children}

            <div className={`arrow-icon-submenu`} />

            <nav className={`nav-dd-submenu ${isOpen ? 'dd-active' : ''}`}>
                <ul className='nav-dd-submenu__list'>
                    {subdata.map((item, index) => {
                        return item['sub'] !== undefined ? (
                            <CustomDropdownSubMenu key={index}><a href={item.href}>{item.text}</a></CustomDropdownSubMenu>
                        ) : (
                            <li className='nav-dd-menu__item' key={index}><a href={item.href}>{item.text}</a></li>
                        )
                    })}
                </ul>
            </nav>
        </li>

    )
}

export default CustomDropdownMenu;
