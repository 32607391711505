import React, { useState } from 'react';
import './RentACarFilters.scss';
import { Button, Col, Collapse, Form, FormLabel, Row } from 'react-bootstrap';
import CollapsedDropdown from '../common/CollapsedDropdown';
import CustomDropdown from '../common/CustomDropdown';
import CustomCheckbox from '../common/CustomCheckbox';

const RentACarFilters = (props) => {

    const { isModal } = props

    const [isOpen, setOpen] = useState(false)
    const testJson1 = [
        'Luxury Car Rental with Driver',
        'Car Rental App Dubai',
        'Bugatti Chiron',
        'Rolls Royce Rental Dubai',
        'Luxury Car Rental with Driver',
        'Car Rental App Dubai',
        'Bugatti Chiron',
        'Rolls Royce Rental Dubai',
        'Luxury Car Rental with Driver',
        'Car Rental App Dubai',
        'Bugatti Chiron',
        'Rolls Royce Rental Dubai',
    ]

    const [carMakeSelectedIndex, setCarMakeSelectedIndex] = useState(-1)
    const onCarMakeSelected = (index) => {
        console.log(index)
        setCarMakeSelectedIndex(index)
    }

    return (
        <div className='rent-a-car-filters'>
            <div className={isModal ? 'd-none' : ''}>
                <div className='elements-list' id="collapse-list">
                    {testJson1.filter((item, index) => index < 4).map((item, index) => {
                        return <button className='text-button' key={index}>
                            <div className='text-button__text'>
                                {item}
                            </div>
                        </button>
                    })}
                </div>
                <Collapse in={isOpen}>
                    <div className='collapse-container'>
                        <div className='elements-list' id="collapse-list">
                            {testJson1.filter((item, index) => index >= 4).map((item, index) => {
                                return <button className='text-button' key={index}>
                                    <div className='text-button__text'>
                                        {item}
                                    </div>
                                </button>
                            })}
                        </div>
                    </div>
                </Collapse>
                <div className='button-container'>
                    <hr className='button-line' />
                    <button className='more-button'
                        onClick={() => setOpen(!isOpen)}
                        aria-controls="collapse-list"
                        aria-expanded={isOpen}>
                        <div className={`more-button__arrow ${isOpen ? 'more-button__up' : ''}`}>

                        </div>
                    </button>
                </div>
            </div>

            <Form style={isModal ? {} : { marginTop: '3rem' }}>
                <CustomCheckbox label={'Verified Cars'} />
                <hr />
                <CustomCheckbox label={'Open Now'} />
                <hr />

                <CollapsedDropdown title='Location'>
                    <CustomDropdown
                        arrayItems={[
                            'Dubai',
                            'Dubai 1',
                            'Dubai 2']}
                        defaultText={'Dubai'}>
                    </CustomDropdown>
                    <FormLabel>
                        Area
                    </FormLabel>
                    <CustomDropdown
                        arrayItems={[
                            'Location 1',
                            'Location 2',
                            'Location 3']}
                        defaultText={'Select Location'}>
                    </CustomDropdown>
                </CollapsedDropdown>

                <hr />

                <CollapsedDropdown title='Car Brand / Model'>
                    <FormLabel>
                        Car Brand
                    </FormLabel>
                    <CustomDropdown
                        arrayItems={[
                            'Brand 0',
                            'Brand 1',
                            'Brand 2']}
                        defaultText={'Select Car Make'}
                        onChange={onCarMakeSelected}
                    >
                    </CustomDropdown>
                    <FormLabel>
                        Car Model
                    </FormLabel>
                    <CustomDropdown
                        arrayItems={[
                            'Location 1',
                            'Location 2',
                            'Location 3']}
                        defaultText={'Select Car Model'}
                        disabledText={'Select brand first'}
                        disabled={carMakeSelectedIndex < 0}
                    >
                    </CustomDropdown>
                </CollapsedDropdown>

                <hr />

                <CollapsedDropdown title='Model Year'>
                    <CustomDropdown
                        arrayItems={[
                            '1923',
                            '2011',
                            '2024']}
                        defaultText={'Select'}
                    >
                    </CustomDropdown>
                </CollapsedDropdown>

                <hr />

                <div className='chekboxes-dropdown'>
                    <CollapsedDropdown title='No. of Seats'>
                        <CustomCheckbox label={'1-2 Seats (151)'} />
                        <CustomCheckbox label={'1-2 Seats (151)'} />
                        <CustomCheckbox label={'1-2 Seats (151)'} />
                        <CustomCheckbox label={'1-2 Seats (151)'} />
                    </CollapsedDropdown>
                </div>

                <hr />

                <div className='chekboxes-dropdown'>
                    <CollapsedDropdown title='Vehicle Type'>
                        <CustomCheckbox label={'Luxury (132)'} />
                        <CustomCheckbox label={'Luxury (132)'} />
                        <CustomCheckbox label={'Luxury (132)'} />
                        <CustomCheckbox label={'Luxury (132)'} />
                        <CustomCheckbox label={'Luxury (132)'} />
                        <CustomCheckbox label={'Luxury (132)'} />
                    </CollapsedDropdown>
                </div>
                <hr />

                <CollapsedDropdown title='Price Range'>
                    <Row className='mt-3'>
                        <Col>
                            <label>Max Day Budget</label>
                        </Col>
                        <Col>
                            <label>Max Monthly Budget</label>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <div className='position-relative'>
                                <Form.Control className='search-input cost-input input-with-suffix' type="text" placeholder='' />
                                <div className='suffix-label'>
                                    AED
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className='position-relative'>
                                <Form.Control className='search-input cost-input input-with-suffix' type="text" placeholder='' />
                                <div className='suffix-label'>
                                    AED
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <Button className='update-button'>
                                Update
                            </Button>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                </CollapsedDropdown>

                <hr />

                <CollapsedDropdown title='Rental Period'>
                    <label className='mt-3 w-100'>Monthly's are most discounted</label>
                    <CustomDropdown
                        arrayItems={[
                            '1923',
                            '2011',
                            '2024']}
                        defaultText={'Select'}
                    />
                    <Button className={`update-button ${isModal ? 'w-50' : ''}`}>
                        Update
                    </Button>
                </CollapsedDropdown>

                <hr />

                <div className='chekboxes-dropdown'>
                    <CollapsedDropdown title='Car Features'>
                        <CustomCheckbox label={'Digital HUD'} />
                        <CustomCheckbox label={'Digital HUD'} />
                        <CustomCheckbox label={'Digital HUD'} />
                        <CustomCheckbox label={'Digital HUD'} />
                        <CustomCheckbox label={'Digital HUD'} />
                        <CustomCheckbox label={'Digital HUD'} />
                    </CollapsedDropdown>
                </div>

                <hr />

                <div className='chekboxes-dropdown'>
                    <CollapsedDropdown title='Payment Mode'>
                        <CustomCheckbox label={'Credit Card'} />
                        <CustomCheckbox label={'Debit Card'} />
                        <CustomCheckbox label={'Cash'} />
                        <CustomCheckbox label={'Cheque'} />
                        <CustomCheckbox label={'Bitcoin / Crypto'} />
                    </CollapsedDropdown>
                </div>

                <hr />

                <div className='chekboxes-dropdown'>
                    <CollapsedDropdown title='Transmission'>
                        <CustomCheckbox label={'Auto (2396)'} />
                        <CustomCheckbox label={'Manual (10)'} />
                    </CollapsedDropdown>
                </div>

                <hr />

                <div className='chekboxes-dropdown'>
                    <CollapsedDropdown title='Fuel Type'>
                        <CustomCheckbox label={'Petrol (2346)'} />
                        <CustomCheckbox label={'Electric (34)'} />
                        <CustomCheckbox label={'Hybrid (13)'} />
                        <CustomCheckbox label={'Diesel (12)'} />
                    </CollapsedDropdown>
                </div>

                <hr />

                <div className='chekboxes-dropdown'>
                    <CollapsedDropdown title='Car Colors'>
                        <CustomCheckbox label={'Red'} />
                        <CustomCheckbox label={'Blue'} />
                        <CustomCheckbox label={'Blue'} />
                        <CustomCheckbox label={'Blue'} />
                        <CustomCheckbox label={'Blue'} />
                        <CustomCheckbox label={'Blue'} />
                        <CustomCheckbox label={'Blue'} />
                    </CollapsedDropdown>
                </div>

                <hr />

                <CollapsedDropdown title='Minimum Required Age'>
                    <CustomDropdown
                        arrayItems={[
                            'Age 1',
                            'Age 2',
                            'Age 3']}
                        defaultText={'Select minimum age'}
                    />
                </CollapsedDropdown>

                <hr />

                <Button className='clear-button'>
                    CLEAR FILTERS
                </Button>
            </Form>

        </div>
    );
}

export default RentACarFilters;
