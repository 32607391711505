import { Button, Container, Form, NavDropdown } from 'react-bootstrap';
import './MainMenu.scss';
import 'font-awesome/css/font-awesome.min.css';

import CustomDropdownMenu from '../common/CustomDropdownMenu';

const MainMenu = (props) => {

    const { commonView, activeItemIndex } = props

    const carsData = {
        href: '#/rentacar',
        items: [
            {
                text: 'Lorem ipsum dolor',
                href: '#/rentacar',
                sub: [
                    {
                        text: 'Lorem ipsum dolor',
                        href: '#/rentacar'
                    },
                    {
                        text: 'Lorem ipsum dolor',
                        href: '#/rentacar'
                    },
                    {
                        text: 'Lorem ipsum dolor',
                        href: '#/rentacar'
                    },
                ]
            },
            {
                text: 'Lorem ipsum dolor',
                href: '#/rentacar'
            },
            {
                text: 'Lorem ipsum dolor',
                href: '#/rentacar'
            },
        ]
    }

    const brandsData = {
        href: '#/carbrands',
        items: [
            {
                text: "BMW",
                img: "bmw",
                href: '#/carbrands'
            },
            {
                text: "Volxwagen",
                img: "vw",
                href: '#/carbrands'
            },
            {
                text: "Audi",
                img: "audi",
                href: '#/carbrands'
            },
            {
                text: "Volvo",
                img: "volvo",
                href: '#/carbrands'
            },
            {
                text: "BMW",
                img: "bmw",
                href: '#/carbrands'
            },
            {
                text: "Volxwagen",
                img: "vw",
                href: '#/carbrands'
            },
            {
                text: "Audi",
                img: "audi",
                href: '#/carbrands'
            },
            {
                text: "Volvo",
                img: "volvo",
                href: '#/carbrands'
            },
            {
                text: "BMW",
                img: "bmw",
                href: '#/carbrands'
            },
            {
                text: "Volxwagen",
                img: "vw",
                href: '#/carbrands'
            },
            {
                text: "Audi",
                img: "audi",
                href: '#/carbrands'
            },
            {
                text: "Volvo",
                img: "volvo",
                href: '#/carbrands'
            },
            {
                text: "BMW",
                img: "bmw",
                href: '#/carbrands'
            },
            {
                text: "Volxwagen",
                img: "vw",
                href: '#/carbrands'
            },
            {
                text: "Audi",
                img: "audi",
                href: '#/carbrands'
            },
            {
                text: "Volvo",
                img: "volvo",
                href: '#/carbrands'
            },
            {
                text: "BMW",
                img: "bmw",
                href: '#/carbrands'
            },
            {
                text: "Volxwagen",
                img: "vw",
                href: '#/carbrands'
            },
            {
                text: "Audi",
                img: "audi",
                href: '#/carbrands'
            },
            {
                text: "Volvo",
                img: "volvo",
                href: '#/carbrands'
            },
            {
                text: "BMW",
                img: "bmw",
                href: '#/carbrands'
            },
            {
                text: "Volxwagen",
                img: "vw",
                href: '#/carbrands'
            },
            {
                text: "Audi",
                img: "audi",
                href: '#/carbrands'
            },
            {
                text: "Volvo",
                img: "volvo",
                href: '#/carbrands'
            },
        ]
    }

    const driverData = {
        href: '#/carwithdriver',
        items: [
            {
                text: 'Lorem ipsum dolor',
                href: '#/carwithdriver',
            },
            {
                text: 'Lorem ipsum dolor',
                href: '#/carwithdriver'
            },
            {
                text: 'Lorem ipsum dolor',
                href: '#/carwithdriver'
            },
        ]
    }

    return (
        <div className={`main-menu-${commonView ? 'common' : 'page'}`}>
            <Container className='menu-container'>
                <div className='dropdowns-block'>
                    <CustomDropdownMenu
                        text={'Rent a car'}
                        icon={'icon-car'}
                        iconActive={'icon-car-red'}
                        isActive={activeItemIndex === 0}
                        data={carsData}
                    />
                    <CustomDropdownMenu
                        text={'Car brands'}
                        icon={'icon-brand'}
                        iconActive={'icon-brand-red'}
                        type={'icon'}
                        isActive={activeItemIndex === 1}
                        data={brandsData}
                    />
                    <CustomDropdownMenu
                        text={'Car with driver'}
                        icon={'icon-driver'}
                        iconActive={'icon-driver-red'}
                        isActive={activeItemIndex === 2}
                        data={driverData}
                    />
                </div>
                {commonView ? (
                    <Form className='search-block'>
                        <Form.Control className='search-input' type="text" placeholder='&#xF002; Find your dream car' />
                        <Button className='search-button' variant="primary" type="submit">
                            Viev All Cars
                        </Button>
                    </Form>
                ) : (
                    <Form className='search-block'>
                        <Form.Control className='search-input' type="text" placeholder='Find your dream car' />
                        <Button className='search-button-mobile' variant="primary" type="submit">
                            <i class="bi bi-search"></i>
                        </Button>
                    </Form>
                )}

            </Container>

        </div>
    );
}

export default MainMenu;
