import { Carousel, Image } from 'react-bootstrap';
import './CarItem.scss';
import { useState } from 'react';

function CarItem(props) {
    const { item, isMobileView } = props

    const [isLiked, setLiked] = useState(false)

    const onLikeClick = (e) => {
        setLiked(!isLiked)
    }

    return (
        <div className={isMobileView ? 'car-item-mobile' : 'car-item'}>
            <a href='#/car/1'>
                <hr className='block-separator1' />
                <hr className='block-separator2' />
                <hr className='contacts-separator' />

                <Carousel className='item-carousel' controls={false}>
                    <Carousel.Item>
                        <Image className='car-img' src={process.env.PUBLIC_URL + `/images/test_car.png`}></Image>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image className='car-img' src={process.env.PUBLIC_URL + `/images/test_car.png`}></Image>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image className='car-img' src={process.env.PUBLIC_URL + `/images/test_car.png`}></Image>
                    </Carousel.Item>
                </Carousel>

                <div className='car-shadow'></div>

                <div className='info-block'>
                    <div className='item-title'>
                        BMW 520I
                    </div>
                    <div className='spec-block'>
                        <div className='spec-item'>
                            <div className='spec-icon spec-icon-car'></div>
                            <div className='spec-text'>
                                Suv
                            </div>
                        </div>
                        <div className='spec-item'>
                            <div className='spec-icon spec-icon-pass'></div>
                            <div className='spec-text'>
                                4 People
                            </div>
                        </div>
                        <div className='spec-item'>
                            <div className='spec-icon spec-icon-door'></div>
                            <div className='spec-text'>
                                4
                            </div>
                        </div>
                        <div className='spec-item'>
                            <div className='spec-icon spec-icon-bag'></div>
                            <div className='spec-text'>
                                3
                            </div>
                        </div>
                    </div>

                    <div className='bordered-block'>
                        <div className='bordered-block-item'>
                            <div className='bordered-block-item__up-part'>
                                <div className='up-part-first-text'>
                                    <s>AED200</s>
                                </div>
                                <div className='up-part-second-text'>
                                    <span className='red-text'>AED 1700</span>/day
                                </div>
                            </div>
                            <div className='bordered-block-item__down-part'>
                                <div className='road-icon'></div>
                                <div className='road-text'>
                                    250 km
                                </div>
                            </div>
                        </div>
                        <div className='bordered-block-item'>
                            <div className='bordered-block-item__up-part'>
                                <div className='up-part-first-text'>
                                    <s>AED200</s>
                                </div>
                                <div className='up-part-second-text'>
                                    <span className='red-text'>AED 1700</span>/day
                                </div>
                            </div>
                            <div className='bordered-block-item__down-part'>
                                <div className='road-icon'></div>
                                <div className='road-text'>
                                    250 km
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='point-marker-big'></div>
                    <div className='turbo-line-img'>

                    </div>
                    <div className='others-list'>
                        <div className='others-list-item'>
                            <div className='others-list-point bg-green'>

                            </div>
                            <div className='others-list-text'>
                                1 day rental available
                            </div>
                        </div>
                        <div className='others-list-item'>
                            <div className='others-list-point bg-orange'>

                            </div>
                            <div className='others-list-text'>
                                Deposit AED 2500
                            </div>
                        </div>
                        <div className='others-list-item'>
                            <div className='others-list-point bg-green'>

                            </div>
                            <div className='others-list-text'>
                                Insurance included
                            </div>
                        </div>
                    </div>
                </div>
            </a>
            <button className='like-block' onClick={onLikeClick}>
                <div className={isLiked ? 'like-liked' : 'like-unliked'}></div>
            </button>
            <div className='verified-block'>
                <div className='verified-icon'></div>
                Verified
            </div>
            <div className='featured-block'>
                <div className='star-icon'></div>
                Featured
            </div>
            <div className='contacts-block'>
                <div className='call-button call-phone'></div>
                <div className='call-button call-telegram'></div>
                <div className='call-button call-whatsapp'></div>
                <div className='call-button call-messenger'></div>
                <div className='call-button call-mail'></div>
            </div>
        </div>
    );
}

export default CarItem;
