import { Carousel, Image } from 'react-bootstrap';
import './DriverItem.scss';

function DriverItem(props) {
    const { item, isMobileView } = props

    return (
        <div className={isMobileView ? 'driver-item-mobile' : 'driver-item'}>
            <hr className='block-separator1' />
            <hr className='block-separator2' />
            <hr className='contacts-separator' />

            <Carousel className='item-carousel' controls={false}>
                <Carousel.Item>
                    <Image className='driver-img' src={process.env.PUBLIC_URL + `/images/test_driver.png`}></Image>
                </Carousel.Item>
                <Carousel.Item>
                    <Image className='driver-img' src={process.env.PUBLIC_URL + `/images/test_driver.png`}></Image>
                </Carousel.Item>
                <Carousel.Item>
                    <Image className='driver-img' src={process.env.PUBLIC_URL + `/images/test_driver.png`}></Image>
                </Carousel.Item>
            </Carousel>

            <div className='driver-shadow'></div>

            {/* <button className='like-block' onClick={onLikeClick}>
                <div className={isLiked ? 'like-liked' : 'like-unliked'}></div>
            </button>
            <div className='verified-block'>
                <div className='verified-icon'></div>
                Verified
            </div>
            <div className='featured-block'>
                <div className='star-icon'></div>
                Featured
            </div> */}

            <div className='info-block'>
                <div className='item-title'>
                    BMW 735 Li 2024 with Driver
                </div>
                <div className='spec-block'>
                    <div className='spec-item'>
                        <div className='spec-text'>
                            Luxury Sedan
                        </div>
                    </div>
                    <div className='spec-item'>
                        <div className='spec-icon spec-icon-door'></div>
                        <div className='spec-text'>
                            4
                        </div>
                    </div>
                    <div className='spec-item'>
                        <div className='spec-icon spec-icon-bag'></div>
                        <div className='spec-text'>
                            3
                        </div>
                    </div>
                </div>

                <div className='bordered-block'>
                    <div className='bordered-block-item'>
                        <div className='bordered-block-item__up-part'>
                            <div className='up-part-second-text'>
                                <span className='red-text'>EUR 831</span>
                            </div>
                        </div>
                        <div className='bordered-block-item__down-part'>
                            <div className='road-text'>
                                5-Hour Service in Abu Dhabi
                            </div>
                        </div>
                    </div>
                    <div className='bordered-block-item'>
                        <div className='bordered-block-item__up-part'>
                            <div className='up-part-second-text'>
                                <span className='red-text'>EUR 831</span>
                            </div>
                        </div>
                        <div className='bordered-block-item__down-part'>
                            <div className='road-text'>
                                10-Hour Service in Abu Dhalouf
                            </div>
                        </div>
                    </div>
                    <button className='button-details'>
                        <div className='button-text'>
                            View details »
                        </div>
                    </button>
                </div>

                <div className="markers">
                    <div className='li-custom'><div className='li-marker'></div>Unmatched luxury: Exquisite sedan for the most discerning travelers</div>
                    <div className='li-custom'><div className='li-marker'></div>Dynamic performance: Powered by a potent twin-turbo engine for exhilarating…</div>
                    <div className='li-custom'><div className='li-marker'></div>Spacious comfort: Accommodates up to four passengers in opulent surroundings…</div>
                </div>

                <div className='contacts-block'>
                    <div className='call-button call-phone'></div>
                    <div className='call-button call-telegram'></div>
                    <div className='call-button call-messenger'></div>
                    <div className='call-button call-whatsapp'></div>
                    <div className='call-button call-mail'></div>
                </div>

            </div>

        </div>
    );
}

export default DriverItem;
