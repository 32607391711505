import './MainPage.scss';
import MainPageHead from '../components/MainPageHead';
import MainMenu from '../components/MainMenu';
import Banner from '../components/Banner';
import CarList from '../components/CarList';
import TextBlock from '../components/TextBlock';
import BrandList from '../components/BrandList';
import LatestCarList from '../components/LatestCarList';
import EliteCarList from '../components/EliteCarList';
import Footer from '../components/Footer';
import { LangContext } from '../common/LanguageWrapper';
import { useContext } from 'react';
import { Container } from 'react-bootstrap';

function MainPage() {

    const langContext = useContext(LangContext);

    return (
        <>
            {langContext.isDataLoaded &&
                <div>
                    <MainPageHead />
                    <MainMenu commonView={true} />
                    <Container>
                        <Banner urls={[
                            process.env.PUBLIC_URL + '/images/banner1.png',
                            process.env.PUBLIC_URL + '/images/banner1.png',
                            process.env.PUBLIC_URL + '/images/banner2.png',
                            process.env.PUBLIC_URL + '/images/banner2.png',
                            process.env.PUBLIC_URL + '/images/banner2.png'
                        ]} />
                    </Container>
                    <CarList />
                    <TextBlock />
                    <BrandList />
                    <LatestCarList />
                    <Container>
                        <Banner urls={[
                            process.env.PUBLIC_URL + '/images/banner1.png'
                        ]} />
                    </Container>
                    <EliteCarList />
                    <Container>
                        <Banner urls={[
                            process.env.PUBLIC_URL + '/images/banner2.png'
                        ]} />
                    </Container>
                    <Footer />
                </div>
            }
        </>
    );
}

export default MainPage;
