import { Carousel, Image } from 'react-bootstrap';
import './Banner.scss';

function Banner(props) {
    const { inlist, urls } = props

    return (
        <div className={`banner ${inlist ? 'inlist' : ''}`}>
            {urls.length <= 1 ? (
                <Image style={{ maxWidth: '100%', minWidth: '100%' }} src={urls[0]} />
            ) : (
                <Carousel controls={false}>
                    {urls.map((url, index) => {
                        return <Carousel.Item key={index}>
                            <Image style={{ maxWidth: '100%', minWidth: '100%' }} src={url} />
                        </Carousel.Item>
                    })}

                </Carousel>
            )}

        </div>
    );
}

export default Banner;
