import { Container } from 'react-bootstrap';
import './DriverPromo.scss';

function DriverPromo(props) {

    return (
        <div className={`driver-promo`}>
            <Container>
                <div className='title'>
                    Professional <span className='red'>Chauffeur</span> Service Abu Dhabi
                </div>
                <p>
                    Get the best on-ground transportation 24x7 across the emirates. Our experienced drivers and range of premium cars are available 24x7. One Click Drive offers the most dependable chauffeured car services in the UAE: Be it a limo pick-up for your VIP guests at the airport or a family day out. Make a hassle-free booking today!
                </p>
                <p>
                    We offer the most affordable yet perfect car and driver service across the UAE including hourly chauffeur service, airport transfer service, limousine service, event transportation and so on. For custom / bulk bookings, feel free to get in touch with us.
                </p>
            </Container>
        </div>
    );
}

export default DriverPromo;
