import { Image } from 'react-bootstrap';
import './BrandItem.scss';

function BrandItem(props) {

    const item = {
        img: 'bmw',
        title: 'BMW',
        text: '330i, X6 xdrive…',
        add: '150+ CAR RENTAL OFFERS »'
    }

    return (
        <button className='brand-item'>
            <a href='#/rentacar'>
                <Image className='brand-img' src={process.env.PUBLIC_URL + `/images/brands/${item.img}.png`}></Image>
                <div className='brand-item-title'>
                    {item.title}
                </div>
                <div className='brand-item-text'>
                    {item.text}
                </div>
                <div className='brand-item-add'>
                    {item.add}
                </div>
            </a>
        </button>
    );
}

export default BrandItem;
