// import logo from './logo.svg';
import { Outlet } from "react-router-dom"
import './App.scss';
import AppHeader from './assets/components/components/AppHeader';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {

  return (
    <div className="App">
      <AppHeader />
      <Outlet />
    </div>
  );
}

export default App;
