import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import LanguageWrapper from './assets/components/common/LanguageWrapper';
import MainPage from './assets/components/pages/MainPage';
import RentACarPage from './assets/components/pages/RentACarPage';
import CarBrandsPage from './assets/components/pages/CarBrandsPage';
import CarWithDriverPage from './assets/components/pages/CarWithDriverPage';
import { createBrowserRouter, createHashRouter, RouterProvider } from "react-router-dom"
import CarPage from './assets/components/pages/CarPage';

// const router = createBrowserRouter([
const router = createHashRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <MainPage />,
      },
      {
        path: "rentacar",
        element: <RentACarPage />,
      },
      {
        path: "carbrands",
        element: <CarBrandsPage />,
      },
      {
        path: "carwithdriver",
        element: <CarWithDriverPage />,
      },
      {
        path: "car/:id",
        element: <CarPage />,
      },
    ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <LanguageWrapper>
    <RouterProvider router={router} />
  </LanguageWrapper>
  // </React.StrictMode>
)