import { useState } from 'react';
import './CustomDropdownMobileMenu.scss';
import { Image } from 'react-bootstrap';

function CustomDropdownMobileMenu(props) {

    const { type, text, icon, data, onMenuClick } = props

    const [isOpen, setOpen] = useState(false)

    const onClick = () => {
        if (type === 'icon') {
            console.log(data)
        }
        setOpen(!isOpen)
    }

    return (<div className='nav-dd nav-mobile'>
        <button className='nav-dd nav-mobile'
            onClick={onClick}>
            <div className='nav-mobile-toggle'>
                <span className={`drop-span`}>
                    <i className={`icon-dd ${icon}`}></i>
                    {text}
                </span>
                <div className={`mobile-arrow-icon`} />
            </div>

        </button>
        <nav className={`nav-mobile-menu ${isOpen ? 'mobile-menu-active' : ''}`}>
            {type === 'icon' ? (
                <ul className={`nav-mobile-menu__list ${isOpen ? 'nav-mobile-menu__list-active' : ''}`}>
                    {data !== undefined && data.items !== undefined && data.items.map((item, index) => {
                        return <li className='nav-mobile-menu__grid-item' key={index}>
                            <a href={item.href} onClick={onMenuClick}>
                                <Image className='brand-img' src={process.env.PUBLIC_URL + `/images/brands/${item.img}.png`}></Image>
                                {item.text}
                            </a>
                        </li>
                    })}
                </ul>
            ) : (
                <ul className={`nav-mobile-menu__list ${isOpen ? 'nav-mobile-menu__list-active' : ''}`}>
                    {data !== undefined && data.items !== undefined && data.items.map((item, index) => {
                        return item['sub'] !== undefined ? (
                            <CustomDropdownMobileSubMenu subdata={item.sub} key={index}>{item.text}</CustomDropdownMobileSubMenu>
                        ) : (
                            <li className='nav-mobile-menu__item' key={index}><a href={item.href} onClick={onMenuClick}>{item.text}</a></li>
                        )
                    })}
                </ul>
            )}

        </nav>
    </div>

    );

    function CustomDropdownMobileSubMenu(props) {

        const { subdata } = props

        const [isOpen, setOpen] = useState(false)

        const onClick = () => {
            setOpen(!isOpen)
        }

        return (
            <div>
                <li className='nav-mobile-menu__item'
                    onClick={onClick}>

                    {props.children}

                    <div className={`mobile-arrow-icon`} />
                </li>
                <div className={`nav-mobile-submenu ${isOpen ? 'mobile-menu-active' : ''}`}>
                    {subdata.map((item, index) => {
                        return item['sub'] !== undefined ? (
                            <CustomDropdownMobileSubMenu key={index}><a href={item.href}>{item.text}</a></CustomDropdownMobileSubMenu>
                        ) : (
                            <li className='nav-mobile-menu__item nav-mobile-submenu__item' key={index}><a href={item.href} onClick={onMenuClick}>{item.text}</a></li>
                        )
                    })}
                    {/* </ul> */}
                </div>
            </div>
        )
    }
}

export default CustomDropdownMobileMenu;
