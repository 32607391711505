import { Button } from 'react-bootstrap'
import './FilterButton.scss'

const FilterButton = (props) => {

    const {onClick} = props

    return (
        <div className='filter-button-container'>
            <Button className='filter-button' onClick={onClick}>
                Filter
            </Button>
        </div>
    )
}

export default FilterButton
