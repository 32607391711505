import { useContext, useEffect, useState } from 'react';
import './CarWithDriverPage.scss';
import { LangContext } from '../common/LanguageWrapper';
import useWindowDimensions from '../hooks/useWindowDimensions';
import CarWithDriverFilters from '../components/CarWithDriverFilters';
import CarWithDriverPageHead from '../components/CarWithDriverPageHead';
import DriverItem from '../components/DriverItem';
import Footer from '../components/Footer';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import FilterButton from '../components/FilterButton';
import MainMenu from '../components/MainMenu';
import Banner from '../components/Banner';
import ReactPaginate from 'react-paginate';
import DriverPromo from '../components/DriverPromo';
import DriverArticle from '../components/DriverArticle';

function CarWithDriverPage() {

    const langContext = useContext(LangContext)

    const [mobileFiltersShow, setMobileFiltersShow] = useState(false);
    const [isMobileItems, setMobileItems] = useState(false);

    const { width } = useWindowDimensions()
    // const xxl = 1400
    // const xl = 1200
    const lg = 992
    // const md = 768
    // const sm = 576

    useEffect(() => {
        if (width <= lg) {
            setMobileItems(true)
        } else {
            setMobileItems(false)
        }
    }, [width]);

    const items = [
        <DriverItem isMobileView={isMobileItems} />,
        <DriverItem isMobileView={isMobileItems} />,
        <DriverItem isMobileView={isMobileItems} />,
        <DriverItem isMobileView={isMobileItems} />,
        <DriverItem isMobileView={isMobileItems} />,
        <DriverItem isMobileView={isMobileItems} />,
        <DriverItem isMobileView={isMobileItems} />,
        <DriverItem isMobileView={isMobileItems} />,
        <DriverItem isMobileView={isMobileItems} />,
        <DriverItem isMobileView={isMobileItems} />,
        <DriverItem isMobileView={isMobileItems} />,
        <DriverItem isMobileView={isMobileItems} />,
        <DriverItem isMobileView={isMobileItems} />,
        <DriverItem isMobileView={isMobileItems} />,
        <DriverItem isMobileView={isMobileItems} />,
        <DriverItem isMobileView={isMobileItems} />,
        <DriverItem isMobileView={isMobileItems} />,
        <DriverItem isMobileView={isMobileItems} />,
        <DriverItem isMobileView={isMobileItems} />,
        <DriverItem isMobileView={isMobileItems} />,
        <DriverItem isMobileView={isMobileItems} />
    ]

    const Items = ({ currentItems }) => {
        return (
            <>
                {currentItems &&

                    currentItems.filter((item, index) => index < 4).map((item, index) => {
                        return <div>
                            {item}
                        </div>
                    })
                }

                {!isMobileItems &&
                    <Banner inlist={true} urls={[
                        process.env.PUBLIC_URL + '/images/banner1.png'
                    ]} />
                }

                {currentItems &&

                    currentItems.filter((item, index) => index >= 4).map((item, index) => {
                        return <div>
                            {item}
                        </div>

                    })}
            </>
        );
    }

    const PaginatedItems = ({ itemsPerPage }) => {
        const [itemOffset, setItemOffset] = useState(0)

        // Simulate fetching items from another resources.
        // (This could be items from props; or items loaded in a local state
        // from an API endpoint with useEffect and useState)
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);

        const currentItems = items.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(items.length / itemsPerPage);

        // Invoke when user click to request another page.
        const handlePageClick = (event) => {
            const newOffset = (event.selected * itemsPerPage) % items.length;
            console.log(
                `User requested page number ${event.selected}, which is offset ${newOffset}`
            );
            setItemOffset(newOffset);

        };
        return (
            <>
                <Row>
                    <Col xs={0} lg={4}>
                    </Col>
                    <Col xs={12} lg={4} className='pag-label'>
                        Showing {itemOffset + 1} - {endOffset > items.length ? items.length : endOffset} of {items.length} cars
                    </Col>
                    <Col xs={12} lg={4}>
                        <ReactPaginate
                            // breakLabel="..."
                            previousLabel={'Prev'}
                            nextLabel={'Next'}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            renderOnZeroPageCount={null}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    </Col>
                </Row>
                <div className='driver-list'>
                    <Items currentItems={currentItems} />
                </div>
                <Row>
                    <Col xs={0} lg={8}>
                    </Col>
                    <Col xs={12} lg={4}>
                        <ReactPaginate
                            // breakLabel="..."
                            previousLabel={'Prev'}
                            nextLabel={'Next'}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            renderOnZeroPageCount={null}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    </Col>
                </Row>
            </>
        );
    }

    return (
        <>
            {langContext.isDataLoaded &&
                <div>
                    <FilterButton onClick={() => setMobileFiltersShow(true)} /
                    >
                    <Modal show={mobileFiltersShow} fullscreen={true} onHide={() => setMobileFiltersShow(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Filters</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <CarWithDriverFilters isModal={true} />
                        </Modal.Body>
                    </Modal>

                    <CarWithDriverPageHead />
                    <MainMenu commonView={false} activeItemIndex={2}/>
                    <Container>
                        <div className='car-with-driver-page'>
                            <div className='car-with-driver-controllers'>
                                <CarWithDriverFilters />
                            </div>

                            <div className='car-with-driver-data'>
                                <PaginatedItems itemsPerPage={8} />
                            </div>
                        </div>
                    </Container>
                    <DriverPromo />
                    <DriverArticle />
                    <Footer />
                </div>
            }
        </>
    );
}

export default CarWithDriverPage;
