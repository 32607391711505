import { Button, Collapse } from 'react-bootstrap';
import './CollapsedList.scss';
import { useState } from 'react';
import useWindowDimensions from '../hooks/useWindowDimensions';

function CollapsedList(props) {
    const { sizeCounts, itemsList } = props

    const [isOpen, setOpen] = useState(false)

    const { width } = useWindowDimensions()
    const xxl = 1400
    const xl = 1200
    const lg = 992
    const md = 768
    const sm = 576

    let currentShowDefault = sizeCounts['max']
    if (width <= xxl) {
        currentShowDefault = sizeCounts['xxl']
    }
    if (width <= xl) {
        currentShowDefault = sizeCounts['xl']
    }
    if (width <= lg) {
        currentShowDefault = sizeCounts['lg']
    }
    if (width <= md) {
        currentShowDefault = sizeCounts['md']
    }
    if (width <= sm) {
        currentShowDefault = sizeCounts['sm']
    }

    return (
        <div>
            <div className='elements-list' id="collapse-list">
                {itemsList.filter((item, index) => index < currentShowDefault).map((item, index) => {
                    return item
                })}
            </div>
            {currentShowDefault < itemsList.length &&
                <>
                    <Collapse in={isOpen}>
                        <div className='collapse-container'>
                            <div className='elements-list' id="collapse-list">
                                {itemsList.filter((item, index) => index >= currentShowDefault).map((item, index) => {
                                    return item
                                })}
                            </div>
                        </div>
                    </Collapse>
                    <div className='button-container'>
                        <Button
                            className='more-button'
                            variant={isOpen ? "light" : "primary"}
                            onClick={() => setOpen(!isOpen)}
                            aria-controls="collapse-list"
                            aria-expanded={isOpen}>
                            <div className='button-content'>
                                <div className={`button-text ${isOpen && 'button-text-up'}`}>
                                    See more
                                </div>
                                <div className={isOpen ? 'arrow-up' : 'arrow-down'}></div>
                            </div>
                        </Button>
                    </div>
                </>
            }
        </div>
    );
}

export default CollapsedList;
