import { Container } from 'react-bootstrap';
import './LatestCarList.scss';
import LatestCarListItem from './LatestCarListItem';
import CollapsedList from '../common/CollapsedList';
import Slider from 'react-slick';
import { useRef } from 'react';

function LatestCarList() {

    const sizeCounts = {
        max: 3,
        xxl: 3,
        xl: 2,
        lg: 1,
        md: 1,
        sm: 1
    }

    let itemsList = []
    for (let i = 0; i < 6; i++) {
        itemsList.push(<LatestCarListItem key={i} />)
      }
    

    const carouselRef = useRef()

    const sliderSettings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2.2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1.8,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1.9
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1.3
                }
            }
        ]
    }

    return (
        <Container className='latest-car-list'>
            <div className='title'>
                Latest Car Rental Offers in Dubai
            </div>
            <div className='subtitle'>
                Choose among cars with in-demand driving features and high mileage, and rent a car at the best price. Exclusive car rental discounts, updated seasonally!
            </div>
            <div className='slider-variant'>
                <Slider  {...sliderSettings} ref={carouselRef}>
                    {itemsList.map((item, index) => {
                        return <LatestCarListItem item={item} key={index} />
                    })}
                </Slider>
            </div>
            <div className='collapsed-variant'>
                <CollapsedList sizeCounts={sizeCounts} itemsList={itemsList} />
            </div>
        </Container>
    );
}

export default LatestCarList;
