import { Image } from 'react-bootstrap';
import './BrandListItem.scss';

function BrandListItem(props) {
    const { item, index, onSelect, isSelected } = props

    const onClick = (e) => {
        onSelect(index)
    }

    return (
        <button
            // className={`brand-list-item ${isSelected ? 'selected' : 'unselected'}`}
            className={`brand-list-item unselected`}
            onClick={onClick}
        >
            <a href='#/carbrands'>
                <Image className='brand-img' src={process.env.PUBLIC_URL + `/images/brands/${item.img}.png`}></Image>
                <div className='brand-item-title'>
                    {item.title}
                </div>
            </a>
        </button>
    );
}

export default BrandListItem;
