import { useContext } from 'react';
import './CarBrandsPage.scss';
import { LangContext } from '../common/LanguageWrapper';
import MainMenu from '../components/MainMenu';
import { Container } from 'react-bootstrap';
import Footer from '../components/Footer';
import BrandItem from '../components/BrandItem';

const CarBrandsPage = () => {

    const langContext = useContext(LangContext)

    return (
        <>
            {langContext.isDataLoaded &&
                <div>
                    <MainMenu commonView={false} activeItemIndex={1}/>
                    <Container>
                        <div className='car-brands-page'>
                            <div className='title'>
                                Browse rental cars by brand
                            </div>
                            <div className='subtitle'>
                                2351 cars from 59 auto brands available for hire in Dubai
                            </div>
                            <p>
                                If you’re looking to drive a car model of a specific auto brand in the UAE, you’ve come to the right place. OneClickDrive.com hosts the largest selection of cars for rent in the UAE. Listed below are cars available for hire by every auto brand. Be it cars by Ferrari, Lamborghini, Rolls Royce, Hyundai, Toyota, Honda, Kia and so on.
                            </p>

                            <div className='brands-list'>
                                <BrandItem />
                                <BrandItem />
                                <BrandItem />
                                <BrandItem />
                                <BrandItem />
                                <BrandItem />
                                <BrandItem />
                                <BrandItem />
                                <BrandItem />
                                <BrandItem />
                                <BrandItem />
                                <BrandItem />
                                <BrandItem />
                                <BrandItem />
                                <BrandItem />
                                <BrandItem />
                                <BrandItem />
                                <BrandItem />
                                <BrandItem />
                                <BrandItem />
                                <BrandItem />
                                <BrandItem />
                                <BrandItem />
                                <BrandItem />
                            </div>

                        </div>

                    </Container>
                    <Footer />
                </div>
            }
        </>
    );
}

export default CarBrandsPage;
