import { Container } from 'react-bootstrap';
import './TextBlock.scss';

function TextBlock() {
    return (
        <Container className='main-text-block'>
            Tired of searching for a ‘rent a car near me’? You have reached just the right place. OneClickDrive.com is a leading car rental marketplace in Dubai featuring budget-friendly car hire deals from reliable rental car companies in the region. You can choose from our extensive inventory of over 2000 vehicles listed by trusted car rental businesses in the UAE. Whether you’re a tourist looking for a car facility or a resident in search of long term rentals, we assure you the cheapest rent cars at the best prices starting as low as AED 30 per day.
        </Container>
    );
}

export default TextBlock;
