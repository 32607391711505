import { Dropdown } from 'react-bootstrap';
import './CustomDropdown.scss';
import React, { useEffect, useState } from 'react';

function CustomDropdown(props) {

    const {defaultText, arrayItems, disabledText, disabled, onChange} = props

    const [value, setValue] = useState(defaultText);
        
    useEffect(() => {
        if (disabled) {
            setValue (disabledText)
        } else {
            setValue (defaultText)
        }
      }, [disabled]);

    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            return (
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labeledBy}
                >
                    <ul className="list-unstyled">
                        {React.Children.toArray(children)}
                    </ul>
                </div>
            );
        },
    );

    const onSelect = (index) => {
        setValue(arrayItems[index])
        if (onChange !== undefined && onChange !== null) {
            onChange(index)
        }
    }

    return (
        <Dropdown className='custom-dropdown' onSelect={onSelect}>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic" disabled={disabled}>
                {value}
            </Dropdown.Toggle>

            <Dropdown.Menu as={CustomMenu}>
                {
                    arrayItems.map((item, index) => {
                        return <Dropdown.Item key={index} eventKey={index}>
                            {item}
                        </Dropdown.Item>
                    })
                }
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default CustomDropdown;
