import React, { useContext, useEffect, useState } from 'react';
import './RentACarPage.scss';
import { LangContext } from '../common/LanguageWrapper';
import MainMenu from '../components/MainMenu';
import Footer from '../components/Footer';
import { Container, Modal, Row } from 'react-bootstrap';
import LuxuryCarItem from '../components/LuxuryCarItem';
import Banner from '../components/Banner';
import CarItem from '../components/CarItem';
import FilterButton from '../components/FilterButton';
import RentACarFilters from '../components/RentACarFilters';
import useWindowDimensions from '../hooks/useWindowDimensions';

const RentACarPage = () => {

    const langContext = useContext(LangContext)

    const [mobileFiltersShow, setMobileFiltersShow] = useState(false);
    const [isMobileItems, setMobileItems] = useState(false);

    const { width } = useWindowDimensions()
    // const xxl = 1400
    // const xl = 1200
    const lg = 992
    // const md = 768
    // const sm = 576

    useEffect(() => {
        if (width <= lg) {
            setMobileItems(true)
        } else {
            setMobileItems(false)
        }
    }, [width]);

    return (
        <>
            {langContext.isDataLoaded &&
                <div>
                    <FilterButton onClick={() => setMobileFiltersShow(true)} /
                    >
                    <Modal show={mobileFiltersShow} fullscreen={true} onHide={() => setMobileFiltersShow(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Filters</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <RentACarFilters isModal={true} />
                        </Modal.Body>
                    </Modal>

                    <MainMenu commonView={false} activeItemIndex={0}/>
                    <Container>
                        <div className='rent-a-car-page'>
                            <div className='rent-a-car-controllers'>
                                <RentACarFilters />
                            </div>

                            <div className='rent-a-car-data'>
                                <Banner urls={[
                                    process.env.PUBLIC_URL + '/images/banner1.png'
                                ]} />

                                <div className='luxury-cars-block'>
                                    <div className='title'>
                                        LUXURY CAR RENTAL DUBAI: FREE DELIVERY
                                    </div>
                                    <div className='subtitle'>
                                        Book Luxury Cars for rent in Dubai - No Commission
                                    </div>

                                    <Row className='luxury-cars'>
                                        <LuxuryCarItem />
                                        <LuxuryCarItem />
                                        <LuxuryCarItem />
                                    </Row>
                                </div>
                                <div className='cars-list'>
                                    <CarItem isMobileView={isMobileItems} />
                                    <CarItem isMobileView={isMobileItems} />
                                    <CarItem isMobileView={isMobileItems} />
                                    <CarItem isMobileView={isMobileItems} />
                                    <CarItem isMobileView={isMobileItems} />
                                    <CarItem isMobileView={isMobileItems} />
                                </div>
                            </div>
                        </div>
                    </Container>
                    <Footer />
                </div>
            }
        </>
    );
}

export default RentACarPage;
