import { Button, Container, Form } from 'react-bootstrap';
import './MainPageHead.scss';
import { FormattedMessage } from 'react-intl';

function MainPageHead() {
    return (
        <div className='main-page-head'>
            <div className='main-page-bg'>
                <div className='up-shadow'></div>
                <div className='bottom-shadow'></div>

                <div className='head-right-part'>
                    <div className='icons-part'>
                        {/* <div className='icons'>
                            <div className='icon1'></div>
                            <div className='icon2'></div>
                        </div> */}
                        {/* <div className='label'>
                            <FormattedMessage
                                id={"lorem"}
                                defaultMessage={""}
                            />
                        </div> */}
                    </div>
                    <div className='right-bottom-shadow'></div>
                    <div className='ribbon'></div>
                </div>
                <div className='head-left-part'>
                    <div className='img-mobile2'></div>
                    <div className='img-mobile1'></div>
                </div>
                <Container className='header-container'>
                    <div className='title-block'>
                        <div className='title'>
                            <span className='red'>RENT</span> A CAR IN DUBAI
                        </div>
                        <div className='subtitle'>
                            Book cars, chauffeurs and yachts – pay zero commission!
                        </div>
                    </div>
                    <Form className='search-block'>
                        <Form.Control className='search-input' type="text" placeholder="Find your dream car" />
                        <Button className='search-button' variant="primary" type="submit">
                            <div className='search-icon'></div>
                        </Button>
                    </Form>
                </Container>
                <Container>

                    <div className='download-block'>
                        <button className='download-button icon-app-store'></button>
                        <button className='download-button icon-google-play'></button>
                        <div className='label'>
                            Download the RentMe Mobile App
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default MainPageHead;
