import { useParams } from 'react-router-dom';
import './CarPage.scss';
import "react-image-lightbox/style.css";
import { LangContext } from '../common/LanguageWrapper';
import { useContext, useRef, useState } from 'react';
import { Button, Col, Container, Image, Modal, Row } from 'react-bootstrap';
import MainMenu from '../components/MainMenu';
import Lightbox from 'react-image-lightbox';
import LuxuryCarItem from '../components/LuxuryCarItem';
import Slider from 'react-slick';
import Footer from '../components/Footer';

function CarPage(props) {

    const testGallery = [
        "/images/tcar_1.png",
        "/images/tcar_2.png",
        "/images/tcar_3.png",
        "/images/tcar_4.png",
        "/images/tcar_5.png",
        "/images/tcar_6.png",
    ]

    let itemsList = []
    for (let i = 0; i < 6; i++) {
        itemsList.push(<LuxuryCarItem key={i} />)
    }

    const carouselRef = useRef()

    const sliderSettings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    centerMode: true,
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    centerMode: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1.5,
                    centerMode: true,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    centerMode: true,
                    slidesToShow: 0.9
                }
            }
        ]
    }

    const car_id = useParams()

    const langContext = useContext(LangContext)

    const [isLiked, setLiked] = useState(false)

    const [showGallery, setShowGallery] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const onLikeClick = (e) => {
        setLiked(!isLiked)
    }

    const onShowAllClick = (e) => {
        setShowGallery(true)
    }

    const [modalShow, setModalShow] = useState(false);
    const onReadMoreClick = (e) => {

    }

    const onSeeMoreClick = (e) => {
        setModalShow(true)
    }

    return (
        <>
            {langContext.isDataLoaded &&
                <div className='car-page'>
                    <Container>
                        <MainMenu commonView={false} />
                        <h2>
                            Rent Lamborghini Aventador SVJ Roadster 2022 in Dubai
                        </h2>
                        <div className='car-gallery'>
                            {
                                testGallery.filter((item, index) => index < 3).map((item, index) => {
                                    const itemView = index === 0 ? 'first' : 'other'
                                    return <div className={`car-gallery__item item-${itemView}`}>
                                        <Image src={process.env.PUBLIC_URL + item} onClick={onShowAllClick}></Image>
                                        {index === 0 &&
                                            <button className='like-block' onClick={onLikeClick}>
                                                <div className={isLiked ? 'like-liked' : 'like-unliked'}></div>
                                            </button>
                                        }
                                        {index === 0 &&
                                            <div className='verified-block'>
                                                <div className='verified-icon'></div>
                                                Verified
                                            </div>
                                        }
                                        {index === 0 &&
                                            <div className='featured-block'>
                                                <div className='star-icon'></div>
                                                Featured
                                            </div>
                                        }
                                        {index === 0 &&
                                            <Button className='btn-all btn-all-inf'
                                                variant="light"
                                                onClick={onShowAllClick}
                                            >
                                                Show all photos
                                            </Button>
                                        }
                                        {index === 2 &&
                                            <Button className='btn-all'
                                                variant="light"
                                                onClick={onShowAllClick}
                                            >
                                                Show all photos
                                            </Button>
                                        }
                                    </div>

                                })
                            }

                        </div>
                        <div className='offer-info'>
                            <Row>
                                <Col xs={12} sm={6} xl={3}>
                                    <div className='contacts-block'>
                                        <div className='txt'>
                                            BOOK DIRECTLY FROM SUPPLIER
                                        </div>
                                        <div className='turbo-line-img'>
                                        </div>
                                        <div className='d-flex'>
                                            <div className='others-list-point bg-green'></div>
                                            <div className='txt'>
                                                Open Now
                                            </div>
                                        </div>
                                        <div className='contacts-block'>
                                            <div className='call-button call-phone'></div>
                                            <div className='call-button call-telegram'></div>
                                            <div className='call-button call-whatsapp'></div>
                                            <div className='call-button call-messenger'></div>
                                            <div className='call-button call-mail'></div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} xl={3}>
                                    <div className='points-block'>
                                        <div className='d-flex'>
                                            <div className='others-list-point bg-green'></div>
                                            <div className='txt'>
                                                1 day rental available
                                            </div>
                                        </div>
                                        <div className='d-flex'>
                                            <div className='others-list-point bg-orange'></div>
                                            <div className='txt'>
                                                Deposit AED 2500
                                            </div>
                                        </div>
                                        <div className='d-flex'>
                                            <div className='others-list-point bg-green'></div>
                                            <div className='txt'>
                                                Insurance included
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={6} xs={12}>
                                    <div className='third-block'>
                                        <div className='period-block'>
                                            <div className='period active'>
                                                EUR 2500 <br /><span className='txt-lesser'>/ day</span>
                                            </div>
                                            <div className='period'>
                                                Unavailable <br /><span className='txt-lesser'>/ week</span>
                                            </div>
                                            <div className='period'>
                                                Unavailable <br /><span className='txt-lesser'>/ month</span>
                                            </div>
                                        </div>
                                        <div className='mileage-block'>
                                            <div className='mileage-str'>
                                                <div className='txt'>Included mileage limit</div>
                                                <div className='num'>250 km</div>
                                            </div>
                                            <hr />
                                            <div className='mileage-str'>
                                                <div className='txt'>Additional mileage charge</div>
                                                <div className='num'>EUR 7.5 / km</div>
                                            </div>
                                        </div>
                                    </div>
                                </Col >
                            </Row>
                        </div>
                        <div className='car-info'>
                            <h4>
                                Lamborghini Aventador SV Roadster 2022
                            </h4>
                            <div className='spec-block'>
                                <div className='spec-block-item'>
                                    Supercar
                                </div>
                                <div className='spec-block-item'>
                                    <div className='spec-icon spec-icon-pass'></div>
                                    4 People
                                </div>
                                <div className='spec-block-item'>
                                    <div className='spec-icon spec-icon-door'></div>
                                    4
                                </div>
                                <div className='spec-block-item'>
                                    <div className='spec-icon spec-icon-bag'></div>
                                    3
                                </div>
                            </div>
                        </div>

                        <hr />
                        <div className='cost'>
                            EUR 2500 / Day
                        </div>
                        <div className='description'>
                            <h4>
                                Description & Highlights
                            </h4>
                            <p>
                                Rent and drive this Lamborghini Aventador SV Roadster 2022-model in Dubai, UAE for EUR 2500/day. Rental cost includes basic comprehensive insurance and standard mileage limit of 250 km/day (EUR 7.5 per additional km applicable). Security deposit of EUR 1250 is required. ContactS even Line Luxury Car Rental directly for bookings and inquiries…
                            </p>
                            <Button className='btn-more'
                                variant="light"
                                onClick={onReadMoreClick}
                            >
                                Read More
                            </Button>
                            <div className='extra-table'>
                                <div>
                                    <div className='mileage-str'>
                                        <div className='txt'>Car Registration Year</div>
                                        <div className='num'>2022</div>
                                    </div>
                                    <hr />
                                </div>
                                <div>
                                    <div className='mileage-str'>
                                        <div className='txt'>Gearbox</div>
                                        <div className='num'>Auto</div>
                                    </div>
                                    <hr />
                                </div>
                                <div>
                                    <div className='mileage-str'>
                                        <div className='txt'>Delivery & Pick-up</div>
                                        <div className='num'>Free Delivery</div>
                                    </div>
                                    <hr />
                                </div>
                                <div>
                                    <div className='mileage-str'>
                                        <div className='txt'>Salik / Toll Charges</div>
                                        <div className='num'>EUR 2</div>
                                    </div>
                                    <hr />
                                </div>
                            </div>
                        </div>
                        <div className='features'>
                            <h4>
                                Features & Specs
                            </h4>
                            <div className='spec-block'>
                                <div className='spec-block-item'>
                                    Supercar
                                </div>
                                <div className='spec-block-item'>
                                    <div className='spec-icon spec-icon-pass'></div>
                                    4 People
                                </div>
                                <div className='spec-block-item'>
                                    <div className='spec-icon spec-icon-door'></div>
                                    4
                                </div>
                                <div className='spec-block-item txt-lesser'>
                                    <div className='spec-icon spec-icon-airbags'></div>
                                    Front & Rear Airbags
                                </div>
                                <div className='spec-block-item txt-lesser'>
                                    <div className='spec-icon spec-icon-climat'></div>
                                    Climate Control
                                </div>
                                <div className='spec-block-item txt-lesser'>
                                    <div className='spec-icon spec-icon-climat'></div>
                                    Climate Control
                                </div>
                                <div className='spec-block-item txt-lesser'>
                                    <div className='spec-icon spec-icon-climat'></div>
                                    Climate Control
                                </div>
                            </div>
                            <Button className='btn-more'
                                variant="light"
                                onClick={onSeeMoreClick}
                            >
                                See More
                            </Button>
                        </div>
                        <div className='faqs'>
                            <div className='extra-table'>
                                <div>
                                    <button className='mileage-str' onClick={() => { }}>
                                        <div className='txt'>Supplier Details</div>
                                        <div className={'arrow-right'}></div>
                                    </button>
                                    <hr />
                                </div>
                                <div>
                                    <button className='mileage-str' onClick={() => { }}>
                                        <div className='txt'>Requirement</div>
                                        <div className={'arrow-right'}></div>
                                    </button>
                                    <hr />
                                </div>
                                <div>
                                    <button className='mileage-str' onClick={() => { }}>
                                        <div className='txt'>Frequently Asked Questions</div>
                                        <div className={'arrow-right'}></div>
                                    </button>
                                    <hr />
                                </div>
                                <div>
                                    <button className='mileage-str' onClick={() => { }}>
                                        <div className='txt'>Similar Car Rental Options</div>
                                        <div className={'arrow-right'}></div>
                                    </button>
                                    <hr />
                                </div>
                            </div>
                        </div>

                        <Slider {...sliderSettings} ref={carouselRef}>
                            {itemsList.map((item, index) => {
                                return <LuxuryCarItem item={item} key={index} />
                            })}
                        </Slider>

                    </Container>
                    <Footer />
                </div >
            }
            {showGallery && (
                <Lightbox
                    mainSrc={testGallery[photoIndex]}
                    nextSrc={testGallery[(photoIndex + 1) % testGallery.length]}
                    prevSrc={testGallery[(photoIndex + testGallery.length - 1) % testGallery.length]}
                    onImageLoad={() => {
                        window.dispatchEvent(new Event('resize'));
                    }}
                    onCloseRequest={() => setShowGallery(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex(
                            (testGallery + testGallery.length - 1) % testGallery.length
                        )
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex(
                            (photoIndex + testGallery.length + 1) % testGallery.length
                        )
                    }
                />
            )}

            <MoreModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

function MoreModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                {/* <Modal.Title id="contained-modal-title-vcenter">
                    Car Specs
                </Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
                <h4 className='first-header'>Car Specs</h4>
                <div className='two-columns'>
                    <div className='more-item'>
                        <i className='icon-small icon-speedometer1'></i>
                        Cruise Control: Yes
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-speedometer1'></i>
                        Cruise Control: Yes
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-seat-arrows'></i>
                        Memory Front Seats
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-seat-arrows'></i>
                        Memory Front Seats
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-parking'></i>
                        Parking Assist
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-parking'></i>
                        Parking Assist
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-hud'></i>
                        Digital HUD
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-hud'></i>
                        Digital HUD
                    </div>
                </div>
                <h4>Car Color</h4>
                <div className='two-columns'>
                    <div className='more-item item-color'>
                        Exterior
                    </div>
                    <div className='more-item item-color'>
                        <div className='color-rect' style={{ backgroundColor: '#2948A2' }}></div>
                        Blue
                    </div>
                    <div className='more-item item-color'>
                        Interior
                    </div>
                    <div className='more-item item-color'>
                        <div className='color-rect' style={{ backgroundColor: '#2948A2' }}></div>
                        Blue
                    </div>
                </div>
                <h4>Car Features</h4>
                <div className='two-columns'>
                    <div className='more-item'>
                        <i className='icon-small icon-speedometer1'></i>
                        Cruise Control: Yes
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-speedometer2'></i>
                        Gesture Control
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-seat-arrows'></i>
                        Memory Front Seats
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-handlebar'></i>
                        SRS Airbags
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-parking'></i>
                        Parking Assist
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-seat-right'></i>
                        Front AirBags
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-hud'></i>
                        Digital HUD
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-bluetooth'></i>
                        Bluetooth
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-gps'></i>
                        Built-in GPS
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-heat-waves'></i>
                        Rear AC
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-camera'></i>
                        Reverse Camera
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-door'></i>
                        Power Windows
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-steering'></i>
                        Steering Assist
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-handlebar'></i>
                        Alloy Wheels
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-touch'></i>
                        Touch screen LCD
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-speedometer2'></i>
                        Apple CarPlay
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-car1'></i>
                        Paddle Shift (Tiptronic)
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-door'></i>
                        Power Door Locks
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-seat-left'></i>
                        Power Seats
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-fog-lights'></i>
                        Fog Lights
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-seat-right'></i>
                        Leather Seats
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-pedestrian-gate'></i>
                        Detachable Roof
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-camera'></i>
                        3D Surround Camera
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-speedometer2'></i>
                        Push Button Ignition
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-spot'></i>
                        Blind Spot Warning
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-seat-right'></i>
                        Front & Rear Airbags
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-speedometer1'></i>
                        Adaptive Cruise Control
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-abs'></i>
                        ABS
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-speedometer2'></i>
                        Temperature Controlled Seats
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-speedometer2'></i>
                        Rear AC
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-speedometer2'></i>
                        Sunroof/Moonroof
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-spot'></i>
                        Power Mirrors
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-parking'></i>
                        Parking Sensors
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-seatbelt'></i>
                        Seat Belt Reminder
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-lights'></i>
                        Daytime Running Lights
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-usb'></i>
                        USB
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-door'></i>
                        Tinted Windows
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-gps'></i>
                        Android Auto
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-car1'></i>
                        Powered Tailgate
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-speedometer2'></i>
                        Foldable Armrest
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-car-road'></i>
                        LCD Screens
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-climate'></i>
                        Climate Control
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-air'></i>
                        Air Suspension
                    </div>
                    <div className='more-item'>
                        <i className='icon-small icon-fm'></i>
                        FM Radio
                    </div>
                </div>
            </Modal.Body>
            {/* <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer> */}
        </Modal>
    );
}

export default CarPage;
