import React, { useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'

export const LangContext = React.createContext()

// const local = navigator.language.substring(0, 2).toLowerCase()
const local = 'en'
export let currentDictionary = []

const LanguageWrapper = (props) => {
    const [isDataLoaded, setDataLoaded] = useState(false);
    const [locale, setLocale] = useState(local)
    const [messages, setMessages] = useState(currentDictionary)

    const getData = (locale) => {
        fetch(`lang/${locale}.json`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(function (response) {
                return response.json()
            })
            .then(function (jsonData) {
                setMessages(jsonData)
                setDataLoaded(true)
            })
    }

    useEffect(() => {
        getData(local)
    }, [])

    function selectLanguage(e) {
        const newLocale = e.target.id
        setLocale(newLocale)
        getData(newLocale)
        console.log(e)
        console.log(newLocale)
    }

    function selectLanguageById(id) {
        const newLocale = id
        setLocale(newLocale)
        getData(newLocale)
        console.log(newLocale)
    }

    return (
        <LangContext.Provider value={{ locale, selectLanguage, selectLanguageById, isDataLoaded }}>
            <IntlProvider messages={messages} locale={locale}>
                {props.children}
            </IntlProvider>
        </LangContext.Provider>
    )
}
export default LanguageWrapper