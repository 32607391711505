import { Container } from 'react-bootstrap';
import './CarWithDriverPageHead.scss';

function CarWithDriverPageHead() {
    return (
        <div className='drivers-page-head'>
            <div className='drivers-page-bg'>
                <div className='up-shadow'></div>
                <div className='head-mobile-part'>
                    <div className='img-mobile2'></div>
                    <div className='img-mobile1'></div>
                </div>
                <div className='bottom-shadow'></div>

                <Container className='header-container'>
                    <div className='title-block'>
                        <div className='title'>
                            <span className='red'>RENT CAR</span> WITH DRIVER IN ABU DHABI
                        </div>
                        <div className="markers">
                            <div className='li-custom'><div className='li-marker'></div>A range of chauffeured cars to choose from</div>
                            <div className='li-custom'><div className='li-marker'></div>Dedicated transportation for you or your guests</div>
                            <div className='li-custom'><div className='li-marker'></div>Move about freely, parking fees included</div>
                            <div className='li-custom'><div className='li-marker'></div>Perfect for sightseeing, shopping or business meetings</div>
                        </div>
                    </div>
                </Container>

                <Container>
                    <div className='d-flex flex-row-reverse'>
                        <div className='download-block'>
                            <button className='download-button icon-app-store'></button>
                            <button className='download-button icon-google-play'></button>
                            <div className='label'>
                                Download the RentMe Mobile App
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default CarWithDriverPageHead;
