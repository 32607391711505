import React from 'react';
import './CarWithDriverFilters.scss';
import { Button, Form, FormLabel } from 'react-bootstrap';
import CollapsedDropdown from '../common/CollapsedDropdown';
import CustomDropdown from '../common/CustomDropdown';
import CustomCheckbox from '../common/CustomCheckbox';

const CarWithDriverFilters = (props) => {

    const { isModal } = props

    return (
        <div className='rent-a-car-filters'>
            <Form>
                <CollapsedDropdown title='Location'>
                    <CustomDropdown
                        arrayItems={[
                            'Dubai',
                            'Dubai 1',
                            'Dubai 2']}
                        defaultText={'Dubai'}>
                    </CustomDropdown>
                </CollapsedDropdown>

                <hr />

                <div className='chekboxes-dropdown'>
                    <CollapsedDropdown title='Vehicle Type'>
                        <CustomCheckbox label={'Luxury (132)'} />
                        <CustomCheckbox label={'Luxury (132)'} />
                        <CustomCheckbox label={'Luxury (132)'} />
                        <CustomCheckbox label={'Luxury (132)'} />
                        <CustomCheckbox label={'Luxury (132)'} />
                        <CustomCheckbox label={'Luxury (132)'} />
                    </CollapsedDropdown>
                </div>

                <hr />
                
                <div className='chekboxes-dropdown'>
                    <CollapsedDropdown title='No. of Seats'>
                        <CustomCheckbox label={'1-2 Seats (151)'} />
                        <CustomCheckbox label={'1-2 Seats (151)'} />
                        <CustomCheckbox label={'1-2 Seats (151)'} />
                        <CustomCheckbox label={'1-2 Seats (151)'} />
                    </CollapsedDropdown>
                </div>

                <hr />

                <CollapsedDropdown title='Car Brand'>
                    <FormLabel>
                        Car Brand
                    </FormLabel>
                    <CustomDropdown
                        arrayItems={[
                            'Brand 0',
                            'Brand 1',
                            'Brand 2']}
                        defaultText={'Select Car Make'}
                    >
                    </CustomDropdown>
                </CollapsedDropdown>

                <hr />

                <Button className='clear-button'>
                    CLEAR FILTERS
                </Button>
            </Form>

        </div>
    );
}

export default CarWithDriverFilters;
