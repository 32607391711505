import './CustomCheckbox.scss';
import React, { useEffect, useId, useState } from 'react';

function CustomCheckbox(props) {

    const { label, onChange, currentValue, isRadio = false, value = '' } = props

    const checboxId = useId()

    const [checked, setChecked] = useState(isRadio ? currentValue === value : false);

    const handleChange = () => {
        setChecked(!checked);
        if (onChange !== undefined && onChange !== null) {
            onChange(value)
        }
    }

    useEffect(() => {
        if (isRadio && currentValue === value) {
            setChecked(true)
        } else {
            setChecked(false)
        }
    }, [isRadio, currentValue, value]);

    return (
        <div className='custom-checkbox-container'>
            <input
                className='custom-checkbox'
                type={isRadio ? 'radio' : 'checkbox'}
                id={checboxId}
                checked={checked}
                onChange={handleChange}>
            </input>
            <label htmlFor={checboxId} className='checkbox-label'>
                {label}
            </label>
        </div>
    );
}

export default CustomCheckbox;
