import { Container } from 'react-bootstrap';
import './BrandList.scss';
import BrandListItem from './BrandListItem';
import CollapsedList from '../common/CollapsedList';
import { useState } from 'react';

function BrandList() {
    const testJson = [
        {
            title: "BMW",
            img: "bmw"
        },
        {
            title: "Volxwagen",
            img: "vw"
        },
        {
            title: "Audi",
            img: "audi"
        },
        {
            title: "Volvo",
            img: "volvo"
        },
        {
            title: "BMW",
            img: "bmw"
        },
        {
            title: "Volxwagen",
            img: "vw"
        },
        {
            title: "Audi",
            img: "audi"
        },
        {
            title: "Volvo",
            img: "volvo"
        }
    ]

    const sizeCounts = {
        max: 7,
        xxl: 7,
        xl: 5,
        lg: 4,
        md: 5,
        sm: 4
    }

    const [currentIndex, setCurrentIndex] = useState(-1)

    const onSelect = (index) => {
        // setCurrentIndex(index)
        // console.log(currentIndex)
    }

    const itemsList = testJson.map((item, index) => {
        return <BrandListItem key={index}
            item={item} index={index} onSelect={onSelect} isSelected={currentIndex === index}
        />
    })

    return (
        <Container className='brand-list'>
            <div className='title'>
                Browse by brands
            </div>
            <CollapsedList sizeCounts={sizeCounts} itemsList={itemsList} />
        </Container>
    );
}

export default BrandList;
