import { Container } from 'react-bootstrap';
import './EliteCarList.scss';
import EliteCarListItem from './EliteCarListItem';
import CollapsedList from '../common/CollapsedList';
import { useRef } from 'react';
import Slider from 'react-slick';

function EliteCarList() {

    const sizeCounts = {
        max: 3,
        xxl: 3,
        xl: 2,
        lg: 1,
        md: 1,
        sm: 1
    }

    let itemsList = []
    for (let i = 0; i < 6; i++) {
        itemsList.push(<EliteCarListItem key={i} />)
      }
    

    const carouselRef = useRef()

    const sliderSettings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2.2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1.8,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1.9
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1.3
                }
            }
        ]
    }
    
    return (
        <Container className='elite-car-list'>
            <div className='title'>
                Luxury & Sports Cars
            </div>
            <div className='subtitle'>
                Drive in style! Make your first car rental a great experience with luxury rental vehicles from top brands such as Rolls Royce, BMW, Land Rover, among others.
            </div>
            <div className='slider-variant'>
                <Slider {...sliderSettings} ref={carouselRef}>
                    {itemsList.map((item, index) => {
                        return <EliteCarListItem item={item} key={index} />
                    })}
                </Slider>
            </div>
            <div className='collapsed-variant'>
                <CollapsedList sizeCounts={sizeCounts} itemsList={itemsList} />
            </div>
        </Container>
    );
}

export default EliteCarList;
