import { Image } from 'react-bootstrap';
import './CarListItem.scss';

function CarListItem(props) {
    const { item, index, onSelect, isSelected } = props

    const onClick = (e) => {
        onSelect(index)
    }

    return (
        <button
            // className={`car-list-item ${isSelected ? 'selected' : 'unselected'}`}
            className={`car-list-item unselected`}
            onClick={onClick}
        >
            <a href='#/rentacar'>
                <Image className='car-img' src={process.env.PUBLIC_URL + `/images/cars/${item.img}.png`}>
                </Image>
                <div className='text-block'>
                    <div className='item-title'>
                        {item.title}
                    </div>
                    <div className='count-text'>
                        {item.count} cars
                    </div>
                </div>
            </a>
        </button>

    );
}

export default CarListItem;
